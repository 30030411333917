import React, {useRef, useState} from "react";
import {active, inactive, viewport, carouselFrame, carousel, scroll, scrollItemOuter, scrollItem, navButtons, hd} from "./index.module.less"

import { useInView, InView } from 'react-intersection-observer';

import video1 from "videos/v1.mp4"
import video2 from "videos/v2.mp4"
import video3 from "videos/v3.mp4"
import video4 from "videos/v4.mp4"
import poster1_en from "images/p1_en.png"
import poster2_en from "images/p2_en.png"
import poster3_en from "images/p3_en.png"
import poster4_en from "images/p4_en.png"


export default () => {
    // const {ref:viewVid1, inView:vid1Visible, entry:topEntry } = useInView({threshold: .9,});
    // const {ref:viewVid2, inView:vid2Visible, entry:bottomEntry} = useInView({threshold: .9,});
    // const {ref:viewVid3, inView:vid3Visible} = useInView({threshold: 0.9,});
    // const {ref:viewVid4, inView:vid4Visible} = useInView({threshold: 0.9,});

    const [activeView, setActiveView] = useState(0)
    const [isMoving, setIsMoving] = useState(false)
    const vidRef1 = useRef(null);
    const vidRef2 = useRef(null);
    const vidRef3 = useRef(null);
    const vidRef4 = useRef(null);

    const handlePlayVideo = (idx) => {
        // if(isInView) return
        const references = [vidRef1, vidRef2, vidRef3, vidRef4]
        const vidRef = references[idx]
        vidRef.current.play();
        console.log(references)
    }
    const handleInViewChange = (idx, isInView) => {
        if(isMoving&&idx==activeView){
            setIsMoving(false)
        }
        if(isInView&&!isMoving) setActiveView(idx)
        const references = [vidRef1, vidRef2, vidRef3, vidRef4]
        if(!isInView){
            console.log("Pausing video ", idx)
            const vidRef = references[idx]
            vidRef.current.pause();
        }
    }

    const handleButtonClick = (idx) => {
        setIsMoving(true)
        setActiveView(idx)
        const references = [vidRef1, vidRef2, vidRef3, vidRef4]
        window.scrollBy(0, 0.01);
        const vidRef = references[idx]
        if(vidRef){
            vidRef.current.scrollIntoView({
                behavior: "smooth",
                block:"center",
                inline: "center",
            });
        }
    }

    const centerInView = (idx) => {
        setActiveView(idx)
        const references = [vidRef1, vidRef2, vidRef3, vidRef4]
        window.scrollBy(0, 0.01);
        const vidRef = references[idx]
        if(vidRef){
            vidRef.current.scrollIntoView({
                behavior: "smooth",
                block:"center",
                inline: "center",
            });
        }
    }

    const element4 = useRef(null);
    // const vidRef2 = useRef(null);
    // const vidRef3 = useRef(null);
    // const vidRef4 = useRef(null);
    
    // return (
    //     <video ref={vidRef}>
    //     <source src={[YOUR_SOURCE]} type="video/mp4" />
    //     </video>
    // )
    return <div className={viewport}>
        {/* <button onClick={()=>{
            window.scrollBy(0, 0.01);
            vidRef4.current.scrollIntoView({
                behavior: "smooth",
                block:"center",
                inline: "center",
            });
        }}> HOLA </button> */}
        <div className={carouselFrame}>
            <div className={carousel}>
            <ul className={scroll}>
                <li className={scrollItemOuter}>
                    <div className={scrollItem}>
                    <InView threshold={0.9} as="div" onChange={(isInView, entry) => handleInViewChange(0,isInView)}>
                        <h2>LetsGrow for any kind of data?</h2>
                        <video 
                            onPlay={()=>centerInView(0)}
                            ref={vidRef1}
                            preload="none"
                            controls 
                            style={{ "width": "100%" }} 
                            poster={poster1_en} 
                            className={hd}>
                            <source src={video1} type="video/mp4" />
                        </video>
                    </InView>
                    </div>
                </li>
                <li className={scrollItemOuter}>
                    <div className={scrollItem}>
                        <InView threshold={0.9} as="div" onChange={(isInView, entry) => handleInViewChange(1,isInView)}>
                            <h2>LetsGrow for any kind of crop?</h2>
                            <video 
                                onPlay={()=>centerInView(1)}
                                ref={vidRef2}
                                preload="none"
                                controls 
                                style={{ "width": "100%" }} 
                                poster={poster2_en} 
                                className={hd}>
                                <source src={video2} type="video/mp4" />
                            </video>
                        </InView>
                    </div>
                </li>
                <li className={scrollItemOuter}>
                    <div className={scrollItem}>
                        <InView threshold={0.9} as="div" onChange={(isInView, entry) => handleInViewChange(2,isInView)}>
                            <h2>Self-grow with LetsGrow?</h2>
                            <video 
                                onPlay={()=>centerInView(2)}
                                ref={vidRef3}
                                preload="none"
                                controls 
                                style={{ "width": "100%" }} 
                                poster={poster3_en} 
                                className={hd}>
                                <source src={video3} type="video/mp4" />
                            </video>
                        </InView>
                    </div>
                </li>
                <li className={scrollItemOuter}>
                    <div className={scrollItem}>
                        <InView threshold={0.9} as="div" onChange={(isInView, entry) => handleInViewChange(3,isInView)}>
                            <h2>Is data secure in China?</h2>
                            <video 
                                onPlay={()=>centerInView(3)}
                                ref={vidRef4}
                                preload="none"
                                controls 
                                style={{ "width": "100%" }} 
                                poster={poster4_en} 
                                className={hd}>
                                <source src={video4} type="video/mp4" />
                            </video>
                        </InView>
                    </div>
                </li>
            </ul>
            </div>
        </div>
        <div class={navButtons} style={{display:"flex", flexDirection:"row"}}>
            {[0,1,2,3].map((idx)=>{
                return (
                    <div 
                        key={idx} 
                        className={idx==activeView?active:inactive}
                        onClick={()=>handleButtonClick(idx)}
                        style={{borderRadius:"20px", height:"2px", width:"2px", padding:"8px"}}
                    >
                    </div>
                )
            })}
        </div>
    </div>
}