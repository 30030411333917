// extracted by mini-css-extract-plugin
export var hd = "index-module--hd--qcnPf";
export var sd = "index-module--sd--hdoOc";
export var viewport = "index-module--viewport--1t6L_";
export var carouselFrame = "index-module--carouselFrame--2MDdR";
export var carousel = "index-module--carousel--1bKEV";
export var scroll = "index-module--scroll--27HIw";
export var scrollItemOuter = "index-module--scrollItemOuter--1PWl-";
export var scrollItem = "index-module--scrollItem--OOYeN";
export var navButtons = "index-module--navButtons--1pNQI";
export var active = "index-module--active--2E5H-";
export var inactive = "index-module--inactive--1oheq";