import React, {useRef} from "react";
import {hd, sd, videoContainer} from "./index.module.less"
import video from "videos/trailer_0.mp4"
import video_hd from "images/trailer_hd.mp4"
import poster from "images/trailer_p.png"

import { useInView, InView } from 'react-intersection-observer';

export default () => {
    const vidRef1 = useRef(null);
    const vidRef2 = useRef(null);
    const container1 = useRef(null);

    const handleInViewChange = (ref, isInView) => {
        if(!isInView){
            console.log("Pausing trailer")
            ref.current.pause();
        }
    }

    const centerInView = (ref) => {
        if(ref){
            ref.current.scrollIntoView({
                behavior: "smooth",
                block:"center",
                inline: "center",
            });
            // window.scrollBy(0, -35);
        }
    }

    return <div ref={container1} className={videoContainer}>
        
        <h1>LetsGrow Set and Sail</h1>

        <InView threshold={0.9} as="div" onChange={(isInView, entry) => handleInViewChange(vidRef1,isInView)}>
            <div>
                <video 
                    ref={vidRef1}
                    onPlay={()=>centerInView(container1)}
                    preload="none"
                    controls 
                    // style={{ "width": "100%" }} 
                    poster={poster} 
                    className={sd}>
                    <source src={video} type="video/mp4" />
                </video>
            </div>
        </InView>
        <InView threshold={0.9} as="div" onChange={(isInView, entry) => handleInViewChange(vidRef2,isInView)}>
            <div>
                <video 
                    ref={vidRef2}
                    onPlay={()=>centerInView(container1)}
                    preload="none"
                    controls 
                    poster={poster} 
                    className={hd}>
                    <source src={video} type="video/mp4" />
                </video>
            </div>
        </InView>
    </div>
}