import React from "react";
import Header from "components/shared/Header";
import Layout from "components/shared/Layout";
import Footer from "components/shared/FooterEN";
import Nav from "components/shared/NavEN";
import Meta from "components/shared/Meta";
import { intro } from "../index.module.less";
import { Link } from "gatsby";
import VideoEN from "components/shared/VideoEN";
import CarouselEN from "components/shared/CarouselEN";

export default () => {
  return (
    <>
      <Nav />
      <Meta url="/en/" />
      <Header>
        <div>
        <VideoEN/>
        </div>
        <p></p>
      </Header>
      <Layout>
        <div className={intro}>
          <h2>Features</h2>
          <p>
            LetsGrow.com allows you to register crop-related data in the MyLetsGrow platform.
            Among other things, it is possible to centralise data, to make predictions and to use analysis tools.
            Curious about all the options with MyLetsGrow?
            Read more about our existing features <Link to="/en/features/">here</Link>.
            New features are also being developed all the time.
          </p>
        </div>
      </Layout>
      <CarouselEN/>
      <Footer />
    </>
  );
};
